import React from 'react';
import { useTheme } from 'styled-components';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './IconCompetition.styles';
interface Props extends PropsWithClassName {
  size?: number;
  isHeader?: boolean;
}
const IconCompetition = (props: Props): React.ReactElement => {
  const {
    size,
    dataTestId,
    isHeader
  } = props;
  const theme = useTheme();
  return <Root className={props.className} data-testid={dataTestId ?? 'icon-typingcompetition-root'} width={size ? size : 24} height={size ? size : 24} fill="none" viewBox={'0 0 512 512'} overflow="visible" {...props} data-sentry-element="Root" data-sentry-component="IconCompetition" data-sentry-source-file="IconCompetition.tsx">
      <path d="M470.502 0H41.498C18.616 0 0 18.616 0 41.498v23.233c0 36.674 12.617 72.642 35.526 101.277 19.049 23.812 46.162 42.466 76.347 52.527l17.666 5.889c18.72 31.217 48.779 55.091 84.963 65.717v26.854c0 10.751-8.747 19.498-19.498 19.498-22.882 0-41.498 18.616-41.498 41.498v19.497h-19.498c-6.075 0-11 4.925-11 11V490h-19.497c-6.075 0-11 4.925-11 11s4.925 11 11 11H408.49c6.075 0 11-4.925 11-11s-4.925-11-11-11h-19.497v-81.511c0-6.075-4.925-11-11-11h-19.498v-19.497c0-22.882-18.616-41.498-41.498-41.498-10.751 0-19.498-8.747-19.498-19.498v-26.854c36.156-10.618 66.197-34.464 84.92-65.645l18.583-5.915.143-.046c29.715-9.905 55.764-28.068 75.33-52.526C499.383 137.373 512 101.405 512 64.731V41.498C512 18.616 493.384 0 470.502 0zM52.706 152.266C32.905 127.516 22 96.429 22 64.731V41.498C22 30.747 30.747 22 41.498 22h52.167l15.939 143.451a145.77 145.77 0 007.088 31.459c-25.344-8.888-48.005-24.667-63.986-44.644zM366.992 490H145.008v-70.511h221.984zm-82.961-219.065a10.999 10.999 0 00-8.533 10.72v35.342c0 22.882 18.616 41.498 41.498 41.498 10.751 0 19.498 8.747 19.498 19.498v19.497H175.506v-19.497c0-10.751 8.747-19.498 19.498-19.498 22.882 0 41.498-18.616 41.498-41.498v-35.342a11 11 0 00-8.533-10.72c-51.874-11.936-90.654-55.302-96.499-107.913L115.801 22h280.398L380.53 163.021c-5.844 52.612-44.625 95.978-96.499 107.914zM490 64.731c0 31.697-10.905 62.784-30.706 87.534-16.693 20.866-38.85 36.419-64.12 45.051a145.728 145.728 0 007.221-31.866L418.335 22h52.167C481.253 22 490 30.747 490 41.498z"
    //fill={theme.palette.icon.primary}
    fill={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader} clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="IconCompetition.tsx" />
      <path d="M293.696 196.273c-1.716 0-3.438-.4-5.022-1.214L256 178.288l-32.674 16.771a11 11 0 01-15.858-11.69l6.179-35.178-26.227-24.959a10.999 10.999 0 016.038-18.859l36.465-5.175 16.259-32.166a11.001 11.001 0 0119.634 0l16.259 32.166 36.465 5.175a11 11 0 016.038 18.859l-26.227 24.959 6.179 35.178a11 11 0 01-10.834 12.904zM256 154.924c1.724 0 3.446.404 5.023 1.214l18.012 9.245-3.371-19.19a10.999 10.999 0 013.251-9.872l14.036-13.357-19.647-2.788a11 11 0 01-8.272-5.929L256 96.38l-9.031 17.866a11.001 11.001 0 01-8.272 5.929l-19.647 2.788 14.036 13.357a10.999 10.999 0 013.251 9.872l-3.371 19.19 18.012-9.245a10.992 10.992 0 015.022-1.213z"
    //fill={theme.palette.icon.accent}
    fill={!isHeader ? theme.palette.icon.accent : theme.palette.icon.accentHeader} data-sentry-element="path" data-sentry-source-file="IconCompetition.tsx" />
    </Root>;
};
export default IconCompetition;